import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  LEARNOSITY_EVENTS,
  initItemsApp,
  resetItemsApp,
} from '../../scripts/learnosityScripts.js';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import * as TestStatus from './TestStatus.js';
import * as Storage from '../common/Storage.js';
import { Observable } from '../../scripts/common/Observable.js';
import { useExternalScript } from '../../hooks/useExternalScript.js';
import * as PwaEvents from '../../context/PwaEvents.js';

export const LearnosityContainerObservable = new Observable();

const LearnosityContainer = () => {
  const learnosityState = useExternalScript(
    `https://items.learnosity.com/?${window.env.REACT_APP_LTS_VERSION}`
  );
  const { getStorageItem } = useSessionStorage();
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(true);

  const { learnosityRequestObj } = useContext(AssessmentContext); 

  const learnosityComponentLoading = useRef(false);
  const setLearnosityComponentLoading = (val) => {
    learnosityComponentLoading.current = val;
  };

  const emitEvent = useCallback(
    (event) => {
      LearnosityContainerObservable.notify({ ...event, ready });
    },
    [ready]
  );

  const readyListener = useCallback(() => {
    console.log('Learnosity Assessment API is ready');
    setOpen(false);
    setReady(true);
    setLearnosityComponentLoading(false);
  }, []);

  const errorListener = useCallback(
    (error) => {
      console.error('Error received from learnosity');
      console.error(error);
      setOpen(false);
      setLearnosityComponentLoading(false);
      emitEvent({ eventName: PwaEvents.LEARNOSITY_LAUNCH_FAIL });
    },
    [emitEvent]
  );

  const learnosityComponentCallback = useMemo(() => {
    return { readyListener, errorListener };
  }, [readyListener, errorListener]);

  useEffect(() => {
    //Update the save state so that error dialog can be displayed after
    //the learnosity container is ready (true/false)
    if (ready) {
      const __saveState = getStorageItem(Storage.STORAGE_SAVE_STATE);
      let event = LEARNOSITY_EVENTS.TEST_SAVE_SUCCESS;
      if (__saveState === TestStatus.SAVE_ERROR) {
        event = LEARNOSITY_EVENTS.TEST_SAVE_ERROR;
      }
      emitEvent({ eventName: event });
    }
  }, [ready, getStorageItem, emitEvent]);

  useEffect(() => {
    if (learnosityState !== 'ready') {
      return;
    }
    var itemsApp = null;
    const callLearnosityAPI = async () => {
      console.log('Loading Learnosity section...');
      try {
        if (!learnosityComponentLoading.current) {
          resetItemsApp(itemsApp);
          console.log('Calling initItemsApp...');
          setLearnosityComponentLoading(true);
          itemsApp = initItemsApp(
            learnosityRequestObj,
            learnosityComponentCallback,
            emitEvent
          );
        }
      } catch (error) {
        console.log(
          'Error occurred while loading learnosity component. ',
          error
        );
        setOpen(false);
        //handleAssessmentLaunchError(error.message ? error.message : error);
        emitEvent({ eventName: 'learnosity:launch:fail', error: error });
      }
    };
    callLearnosityAPI().catch(console.error);

    //unmounting cleanup
    return () => {
      console.log('unmount');
      //only reset if learnosity component is not currently loading, meaning init is done/ready
      if (!learnosityComponentLoading.current) {
        resetItemsApp(itemsApp);
      }
    };
  }, [
    emitEvent,
    learnosityRequestObj,
    learnosityComponentCallback,
    learnosityState,
  ]);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div id="learnosity_assess" style={{ minHeight: '85vh' }}></div>
    </React.Fragment>
  );
};

export default LearnosityContainer;
