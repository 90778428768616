import { useMemo, useCallback } from 'react';

const useSessionStorage = () => {
  //Check if hook is invoked from the client and has sessionStorage
  const hasSessionStorage = useMemo(() => {
    try {
      const key = `__storage__test`;
      window.sessionStorage.setItem(key, null);
      window.sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  /**
   * Get item in storage if hook is invoked from client.
   * @param {*} key Item Key
   * @returns The value saved against the key. Returns JSON is object otherwise string.
   */
  const getStorageItem = useCallback((key) => {
    const value = sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }, []);

  /**
   * Saves item in storage if hook is invoked from client. Value is saved as string.
   * @param {*} key Item Key
   * @param {*} value Can be an object or string. It is converted to string before save.
   * @returns Returns true if item was saved else false
   */
  const setStorageItem = useCallback((key, value) => {
    try {
      const val =
        value && typeof value === 'object' ? JSON.stringify(value) : value;
      sessionStorage.setItem(key, val);
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  /**
   * Removes the item from storage.
   * @param {*} key
   */
  const removeStorageItem = useCallback((key) => {
    sessionStorage.removeItem(key);
  }, []);

  /**
   * Clears session storage
   */
  const clearStorage = useCallback(() => {
    sessionStorage.clear();
  }, []);

  return {
    getStorageItem,
    setStorageItem,
    removeStorageItem,
    clearStorage,
    hasSessionStorage,
  };
};

export default useSessionStorage;
