import { useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import ErrorDialog from './ErrorDialog.js';
import * as PwaEvents from '../../context/PwaEvents.js';
import {
  getCustomDialogButtons,
  showCustomDialog,
} from '../../scripts/learnosityScripts.js';

const NetworkOrSaveErrorDialog = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { clearStorage } = useSessionStorage();
  const { learnosityItemApp } = useContext(AssessmentContext);
  const { sendMsgToWebsocket } = useContext(WebSocketContext);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  //Dialog actions for Awaiting authorization
  const EXIT_CONFIRMATION_DIALOG_ACTIONS = [
    {
      id: 'wp_continuewaiting',
      label: 'No, continue waiting',
      handle: () => {
        setOpenConfirmationDialog(false);
      },
      primary: true,
    },
    {
      id: 'wp_exit',
      label: 'Yes',
      handle: async () => {
        setOpenConfirmationDialog(false);
        onComplete();
      },
      primary: false,
    },
  ];

  //learnosity custom dialog detailss
  const EXIT_CONFIRMATION_DIALOG_DETAIL = {
    header: 'Confirmation',
    body: '<h5>This will end the test and return you to the login screen.  Do you want to log out?</h5>',
    buttons: getCustomDialogButtons(
      EXIT_CONFIRMATION_DIALOG_ACTIONS,
      learnosityItemApp
    ),
  };

  const onComplete = useCallback(() => {
    sendMsgToWebsocket(PwaEvents.TEST_ABORTED_COMMUNICATION);
    clearStorage();
    setOpen(false);
    navigate('/login');
  }, [navigate, clearStorage, sendMsgToWebsocket, setOpen]);

  const NETWORKORSAVE_ERROR_ACTIONS = [
    {
      id: 'wp_networkorsave_error_exit',
      label: 'Exit',
      handle: () => {
        setOpenConfirmationDialog(true);
      },
      primary: true,
    },
  ];

  const getComponentCallback = useCallback((countdownSeconds) => {
    return `<h5>
        Lost connectivity to testing services, attempting to reconnect.<br /><br /> If you
        wish to try again later, press Exit.<br /><br /> Closing assessment in
        <b>${countdownSeconds}</b> if attempts to reconnect fail.
      </h5>`;
  }, []);

  return (
    <>
      {openConfirmationDialog &&
        showCustomDialog(learnosityItemApp, EXIT_CONFIRMATION_DIALOG_DETAIL)}
      <ErrorDialog
        runBackground={openConfirmationDialog}
        open={open}
        title="Lost connection to testing services"
        getComponentCallback={getComponentCallback}
        onComplete={onComplete}
        actions={NETWORKORSAVE_ERROR_ACTIONS}
        duration={600}
      />
    </>
  );
};

export default NetworkOrSaveErrorDialog;
