import React, { createContext, useCallback, useState } from 'react';
import useSessionStorage from '../hooks/useSessionStorage';
import * as TestStatus from '../components/assessment/TestStatus.js';
import * as Storage from '../components/common/Storage.js';

export const AssessmentContext = createContext();

/**
 *
 * @param {string} props.wssURL URL for the websocket
 * @param {string} props.authToken The authentication token to be passed for websocket connection
 * @returns
 */
export const AssessmentProvider = ({ children }) => {
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useSessionStorage();
  const __signedObject = getStorageItem(Storage.STORAGE_SIGNED_OBJECT);

  //Set the save and error state from session storage
  const __saveErrorCount =
    getStorageItem(Storage.STORAGE_SAVE_ERROR_COUNT) || 0;
  //The value is set in the Learnosity container component once Learnosity is ready
  const [saveState, setSaveState] = useState();
  const [saveErrorCount, setSaveErrorCount] = useState(__saveErrorCount);

  //Error dialogs
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openContinueDialog, setOpenContinueDialog] = useState(false);
  const [openLaunchErrorDialog, setOpenLaunchErrorDialog] = useState(false);

  //Note: if assessment is loaded, it means user is at least in the "authenticated" status
  //The layout will also check for authToken and throw permission denied.
  const __storedTestStatus = getStorageItem(Storage.STORAGE_TEST_STATUS);

  const [testStatus, setTestStatus] = useState(__storedTestStatus);
  const [learnosityRequestObj, setLearnosityRequestObj] =
    useState(__signedObject);

  const [learnosityItemApp, setLearnosityItemApp] = useState();
  const __storedPausedBy = getStorageItem(Storage.STORAGE_PAUSED_BY);
  const [pausedBy, setPausedBy] = useState(__storedPausedBy);

  const updateLearnosityRequestObject = useCallback(
    (signedObject) => {
      setLearnosityRequestObj(signedObject);
      setStorageItem(Storage.STORAGE_SIGNED_OBJECT, signedObject);
    },
    [setStorageItem]
  );

  //Save Error count
  const updateSaveErrorCount = useCallback(() => {
    setSaveErrorCount((prev) => {
      let newVal = prev + 1;
      setStorageItem(Storage.STORAGE_SAVE_ERROR_COUNT, newVal);
      return newVal;
    });
  }, [setStorageItem]);

  const resetSaveErrorCount = useCallback(() => {
    setSaveErrorCount(0);
    removeStorageItem(Storage.STORAGE_SAVE_ERROR_COUNT);
  }, [removeStorageItem]);

  const updateSaveState = useCallback(
    (state) => {
      setSaveState(state);
      setStorageItem(Storage.STORAGE_SAVE_STATE, state);
    },
    [setStorageItem]
  );

  const updateLaunchError = useCallback((state) => {
    setOpenLaunchErrorDialog(state);
  }, []);

  const applySaveStateChanges = useCallback(
    (state, incrErrorCount = false) => {
      updateSaveState(state);
      if (state === TestStatus.SAVE_ERROR && incrErrorCount) {
        updateSaveErrorCount();
      }
    },
    [updateSaveState, updateSaveErrorCount]
  );

  const updateTestStatus = useCallback(
    (status) => {
      setTestStatus(status);
      setStorageItem(Storage.STORAGE_TEST_STATUS, status);
    },
    [setStorageItem]
  );

  const updatePausedBy = useCallback(
    (status) => {
      setPausedBy(status);
      setStorageItem(Storage.STORAGE_PAUSED_BY, status);
    },
    [setStorageItem]
  );

  const resetPausedBy = useCallback(() => {
    setPausedBy(undefined);
    removeStorageItem(Storage.STORAGE_PAUSED_BY);
  }, [removeStorageItem]);

  return (
    <AssessmentContext.Provider
      value={{
        testStatus,
        learnosityRequestObj,
        openErrorDialog,
        openContinueDialog,
        openLaunchErrorDialog,
        saveState,
        saveErrorCount,
        learnosityItemApp,
        pausedBy,
        setLearnosityItemApp,
        updateLearnosityRequestObject,
        setOpenErrorDialog,
        setOpenContinueDialog,
        updateSaveErrorCount,
        resetSaveErrorCount,
        updateSaveState,
        updateLaunchError,
        applySaveStateChanges,
        updateTestStatus,
        updatePausedBy,
        resetPausedBy,
      }}
    >
      {children}
    </AssessmentContext.Provider>
  );
};
