import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import {
  Box,
  CssBaseline,
  Backdrop,
  CircularProgress,
  Toolbar,
} from '@mui/material';
import CustomDialog from '../ui/feedback/CustomDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebSocketProvider } from '../../context/WebSocketContext';
import useSessionStorage from '../../hooks/useSessionStorage';
import { AssessmentProvider } from '../../context/AssessmentContext';
import { KioskContext } from '../../context/KioskProviderContext';

const Layout = ({ disableMenu, login, landing }) => {
  const { isLoading, setIsLoading } = useContext(KioskContext);
  const [errorDialogProps, setErrorDialogProps] = useState();
  const [errorWithSetup, setErrorWithSetup] = useState(false);
  const { hasSessionStorage, getStorageItem, clearStorage } = useSessionStorage();
  const navigate = useNavigate();
  const { state } = useLocation();
  const authToken = getStorageItem('authToken');

  const accessErrorActions = useMemo(() => {
    return [
      {
        label: 'Go to login',
        handle: () => {
          setErrorWithSetup(false);
          navigate('/login');
        },
      },
    ];
  }, [navigate]);

  useEffect(() => {
    if (!hasSessionStorage) {
      setErrorWithSetup(true);
      setErrorDialogProps({
        title: 'Error with application setup',
        message:
          'Session Storage is not supported or is disabled. You cannot proceed. Please contact your administrator.',
      });
    }
    if (!login && !landing && !authToken) {
      setErrorWithSetup(true);
      setErrorDialogProps({
        title: 'Access denied',
        message: 'Please login to access the testing service.',
        actions: accessErrorActions,
      });
    }
  }, [hasSessionStorage, login, landing, accessErrorActions, authToken]);

  //Clear storage is back or forward button is pressed
  window.onpopstate = (event) => {
    if(hasSessionStorage) {
      clearStorage();
    }
  };

  const nonLandingLayout = (
    <Box sx={{ display: 'flex', overflow: 'auto', height: '100vh' }}>
      <CssBaseline />
      <Header disableMenu={disableMenu} />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexGrow: 1,
          overflow: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          marginTop: '64px',
          height: `calc(100vh - 64px)`,
        }}
      >
        <Toolbar />
        <Outlet context={[setIsLoading]} />
      </Box>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog open={errorWithSetup} dialogProps={errorDialogProps} />
      {landing && (
        <Box display="flex">
          <Header />
          <Box style={{ width: '100vw', height: `calc(100vh - 100px)` }}>
            <Outlet />
          </Box>
        </Box>
      )}
      {login && <>{nonLandingLayout}</>}
      {!login && !landing && authToken && (
        <AssessmentProvider>
          <WebSocketProvider
            wssURL={`${state.wssURL}`}
            authToken={state.authToken}
          >
            {nonLandingLayout}
          </WebSocketProvider>
        </AssessmentProvider>
      )}
    </>
  );
};

export default Layout;
