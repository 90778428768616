import React, { useEffect, useContext, useCallback, useState } from 'react';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import LaunchErrorDialog from './LaunchErrorDialog.js';
import { ReadyState } from 'react-use-websocket';
import { SAVE_ERROR, SAVE_SUCCESS } from './TestStatus.js';
import * as TestStatus from './TestStatus.js';
import * as Storage from '../common/Storage.js';
import useSessionStorage from '../../hooks/useSessionStorage';
import * as PwaEvents from '../../context/PwaEvents.js';

const AssessmentOverlays = (props) => {
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useSessionStorage();
  const {
    openLaunchErrorDialog,
    saveState,
    saveErrorCount,
    setOpenErrorDialog,
    setOpenContinueDialog,
    resetSaveErrorCount,
    updateLaunchError,
    updatePausedBy
  } = useContext(AssessmentContext);

  const { readyState, sendMsgToWebsocket } = useContext(WebSocketContext);

  //Set the save and error state from session storage
  const __notifiedSaveErrorCount =
    getStorageItem(Storage.STORAGE_NOTIFIED_SAVE_ERROR_COUNT) || 0;
  const [notifiedSaveErrorCount, setNotifiedSaveErrorCount] = useState(
    __notifiedSaveErrorCount
  );

  //Notified save error count
  const updateNotifiedSaveErrorCount = useCallback(() => {
    setNotifiedSaveErrorCount((prev) => {
      let newVal = prev + 1;
      setStorageItem(Storage.STORAGE_NOTIFIED_SAVE_ERROR_COUNT, newVal);
      return newVal;
    });
  }, [setStorageItem]);

  const resetNotifiedSaveErrorCount = useCallback(() => {
    setNotifiedSaveErrorCount(0);
    removeStorageItem(Storage.STORAGE_NOTIFIED_SAVE_ERROR_COUNT);
  }, [removeStorageItem]);

  useEffect(() => {
    //there is error between 0 - 2 and save state is success
    if (
      saveErrorCount > 0 &&
      saveErrorCount < TestStatus.MIN_CONSECUTIVE_ERRORS &&
      saveState === SAVE_SUCCESS
    ) {
      //reset save error counts
      resetSaveErrorCount();
      //reset notify error count
      resetNotifiedSaveErrorCount();
    }

    //there is error more than 3 and save state is success
    //if websocket is open send message and reset error count
    //There is case where we will only send reconnect and not disconnect
    if (
      saveErrorCount >= TestStatus.MIN_CONSECUTIVE_ERRORS &&
      saveState === SAVE_SUCCESS &&
      readyState === ReadyState.OPEN 
      //&& notifiedSaveSuccessCount === 0
    ) {
      sendMsgToWebsocket(PwaEvents.CLIENT_LEARNOSITY_RECONNECT);

      //reset save error counts
      resetSaveErrorCount();
      //reset notify error count
      resetNotifiedSaveErrorCount();

      //handle dialogs
      setOpenErrorDialog(false);
      setOpenContinueDialog(true);
    }

    //there is error 3 and above and save state is error
    if (
      saveErrorCount >= TestStatus.MIN_CONSECUTIVE_ERRORS &&
      saveState === SAVE_ERROR
    ) {
      //If socket is open send message
      if (readyState === ReadyState.OPEN && notifiedSaveErrorCount === 0) {
        sendMsgToWebsocket(PwaEvents.CLIENT_LEARNOSITY_DISCONNECT);
        updateNotifiedSaveErrorCount();
      }
      //handle error dialog
      updatePausedBy(TestStatus.PAUSED_BY_SYSTEM);
      setOpenErrorDialog(true);
    }
  }, [
    notifiedSaveErrorCount,
    readyState,
    saveState,
    saveErrorCount,
    sendMsgToWebsocket,
    setOpenContinueDialog,
    setOpenErrorDialog,
    resetSaveErrorCount,
    updatePausedBy,
    updateNotifiedSaveErrorCount,
    resetNotifiedSaveErrorCount,
  ]);

  return (
    <LaunchErrorDialog
      open={openLaunchErrorDialog}
      setOpen={updateLaunchError}
    />
  );
};

export default AssessmentOverlays;
