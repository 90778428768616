import React, { createContext, useState, useContext, useCallback } from 'react';
import useWebSocket from 'react-use-websocket';
import useSessionStorage from '../hooks/useSessionStorage';
import * as PwaStatus from './PwaStatus.js';
import * as WebSocketStatus from './WebSocketStatus';
import * as TestStatus from '../components/assessment/TestStatus.js';
import * as Storage from '../components/common/Storage.js';
import { AssessmentContext } from './AssessmentContext.js';

export const WebSocketContext = createContext();

/**
 *
 * @param {string} props.wssURL URL for the websocket
 * @param {string} props.authToken The authentication token to be passed for websocket connection
 * @returns
 */
export const WebSocketProvider = ({ wssURL, authToken, children }) => {
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useSessionStorage();
  const { testStatus, saveState, saveErrorCount } = useContext(AssessmentContext);
  const [socketStatus, setSocketStatus] = useState(WebSocketStatus.INITIAL);
  const [attemptNumber, setAttemptNumber] = useState(0);
  const attemptNumBeforeReload = getStorageItem(
    Storage.STORAGE_ATTEMPT_NO_BEFORE_RELOAD
  );
  const maxReconnectAttempts = parseInt(
    window.env.REACT_APP_WS_RECONNECT_ATTEMPTS
  );
  const maxReconnectAttemptsBeforeAuthOrReconnecting = parseInt(
    window.env.REACT_APP_WS_ATTEMPT_BEFORE_RECONNECTING_STATUS
  );

  let reconnectAttemptNos = [
    TestStatus.TEST_AWAITING_AUTHORIZATION,
    TestStatus.TEST_AUTHENTICATED,
  ].includes(testStatus)
    ? maxReconnectAttemptsBeforeAuthOrReconnecting
    : maxReconnectAttempts;

  const reconnectAttempts = Math.max(
    reconnectAttemptNos - attemptNumBeforeReload,
    0
  );

  window.onbeforeunload = () => {
    setStorageItem(Storage.STORAGE_ATTEMPT_NO_BEFORE_RELOAD, attemptNumber);
  };

  //Heartbeat ping message
  const heartbeatMsg = {
    action: 'nmcHb',
    message: 'PING',
  };

  const updateAttemptNumber = (value) => {
    setAttemptNumber(value);
    if (value === 0) {
      removeStorageItem(Storage.STORAGE_ATTEMPT_NO);
      removeStorageItem(Storage.STORAGE_ATTEMPT_NO_BEFORE_RELOAD);
    } else {
      setStorageItem(Storage.STORAGE_ATTEMPT_NO, value);
    }
  };

  const getPWAState = useCallback(() => {
    let pwaState;
    switch (testStatus) {
      case TestStatus.TEST_INSESSION:
      case TestStatus.TEST_PAUSED:
        if (
          saveState === TestStatus.SAVE_ERROR &&
          saveErrorCount >= TestStatus.MIN_CONSECUTIVE_ERRORS
        ) {
          pwaState = PwaStatus.LEARNOSITY_DOWN;
        } else {
          //saveState is success or saveStatus is error and error count is between 0 - 2
          pwaState = PwaStatus.LEARNOSITY_UP;
        }
        break;
      case TestStatus.TEST_AWAITING_AUTHORIZATION:
        pwaState = PwaStatus.AWAITING_AUTH;
        break;
      default:
        pwaState = PwaStatus.INITIAL;
        break;
    }
    return pwaState;
  }, [testStatus, saveState, saveErrorCount]);

  //Websocket configuration
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    wssURL,
    {
      shouldReconnect: (closeEvent) => {
        console.log(
          `Websocket Event: shouldReconnect, Code: ${closeEvent.code} , Reason: ${closeEvent.reason}`
        );
        return true;
      },
      //TODO need additional logic on what happens when the socket connection fails during test
      reconnectAttempts: reconnectAttempts,
      reconnectInterval: (__attemptNumber) => {
        const fromStorage = getStorageItem('attemptNumber');
        var savedAttemptNumber = __attemptNumber;
        if (fromStorage) {
          savedAttemptNumber = parseInt(fromStorage) + 1;
        }
        console.log(`Reconnecting... attemptNumber: ${savedAttemptNumber}`);
        updateAttemptNumber(savedAttemptNumber);
        if (
          savedAttemptNumber >= maxReconnectAttemptsBeforeAuthOrReconnecting &&
          socketStatus !== WebSocketStatus.INITIAL
        ) {
          setSocketStatus(WebSocketStatus.RECONNECTING);
        }
        return parseInt(window.env.REACT_APP_WS_RECONNECT_INTERVAL_MS); //30s in milliseconds
      },
      heartbeat: {
        message: JSON.stringify(heartbeatMsg),
        returnMessage: 'ACK',
        timeout: parseInt(window.env.REACT_APP_WS_HEARTBEAT_TIMEOUT_MS), //1min in milliseconds, needs to more than interval - maybe at least 2x
        interval: parseInt(window.env.REACT_APP_WS_HEARTBEAT_INTERVAL_MS), //30s in milliseconds
      },
      protocols: [authToken, getPWAState()],
      onClose: (closeEvent) => {
        console.log(
          `Websocket Event: onClose, Code: ${closeEvent.code} , Reason: ${closeEvent.reason}`
        );
      },
      onError: (error) => {
        console.log(`Websocket Event: onError - ${JSON.stringify(error)}`);
      },
      onOpen: (openEvent) => {
        console.log(
          `Websocket Event: onOpen: connected : ${JSON.stringify(openEvent)}`
        );
        setSocketStatus(WebSocketStatus.OPEN);
        updateAttemptNumber(0);
      },
      onReconnectStop: (numAttempted) => {
        console.log(
          'Websocket Event: onReconnectStop, numAttempted:' + numAttempted
        );
        //TODO may be we so not set socket fail to true once auth is approved (??)
        updateAttemptNumber(0);
        if (socketStatus === WebSocketStatus.INITIAL) {
          setSocketStatus(WebSocketStatus.FAILED_TO_CONNECT);
        } else {
          setSocketStatus(WebSocketStatus.FAILED_TO_RECONNECT);
        }
      },
    }
  );

  /**
   * Uses Websockets sendJsonMessage method to send message to web socket.
   * @param {*} msg If isTestEvent msg is a string otherwise it is an object
   * @param {*} isTestEvent Boolean that indicate if the message is related to test event
   */
  const sendMsgToWebsocket = useCallback(
    (msg, isTestEvent = true, error) => {
      console.log(
        `Sending websocket message... Message - ${msg} ${
          error ? `Error - ${JSON.stringify(error)}` : ''
        }`
      );
      if (isTestEvent) {
        sendJsonMessage({
          action: 'nmcClientMsg',
          message: {
            msg_type: 'TEST_EVENT',
            msg: {
              event_type: msg,
              error: error,
            },
          },
        });
      } else {
        sendJsonMessage({
          action: 'nmcClientMsg',
          message: msg,
        });
      }
    },
    [sendJsonMessage]
  );

  return (
    <WebSocketContext.Provider
      value={{
        wssURL,
        socketStatus,
        readyState,
        sendMsgToWebsocket,
        lastJsonMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
