import { Stack, Skeleton } from '@mui/material';

const SkeletonAssement = () => {
  return (
    <Stack spacing={1} padding={1} sx={{minHeight: '85vh'}}>
      <Skeleton variant="rectangular" height={60} />
      <Stack spacing={2} direction="row">
        <Skeleton variant="rectangular" sx={{display:'flex', flexGrow:1}} height={380} />
        <Stack spacing={1} direction="row">
          <Skeleton variant="rectangular" width={250} height={380} />
          <Skeleton variant="rectangular" width={50} height={380} />
        </Stack>
      </Stack>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Skeleton variant="rectangular" height={60} width={50} />
        <Skeleton variant="rectangular" height={60} width={120} />
      </Stack>
    </Stack>
  );
};

export default SkeletonAssement;
