'use client';

const fetchRetry = require('fetch-retry')(global.fetch);
/**
 * A utility exponential retry delay function.
 * @param {*} attempt current attempt
 * @param {*} error error object
 * @param {*} response HTTP response object
 * @returns
 */
export const expBackOffRetryDelay = function (attempt, error, response) {
  return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
};

/**
 * A utility retry on HTTP status code over 500.
 * @param {*} attempt current attempt
 * @param {*} error error object
 * @param {*} response HTTP response object
 * @param {*} maxAttempts Max allowed attempts
 * @returns
 */
export const retryOnStatusGT500 = (attempt, error, response, maxAttempts) => {
  if (attempt >= maxAttempts) {
    return false;
  }
  // retry on any network error, or > 500 status codes
  if (error !== null || response.status > 500) {
    console.log(`retrying, attempt number ${attempt + 1}`);
    return true;
  }
  return false;
};

/**
 * Node fetch function wrapped for features like retry, errors and backdrop loading.
 * @param {param.url}  url URL to fetch
 * @param {param.retryOn}  retryOn Condition to retry on.
 *    This parameter can be an array of status codes ex. [401,503]
 *    or a method that takes parameters (attempt, error, response, maxAttempts) and returns boolean.
 * @param {param.retries}  retries Maximum number of retries. Default 0.
 * @param {param.retryDelay}  retryDelay Delay between retry.
 * @returns
 */
export const customFetch = async ({
  url,
  method = 'GET',
  headers,
  body,
  retryOn,
  retries = 3,
  retryDelay = expBackOffRetryDelay
}) => {
  const fetchOptions = {
    method: method,
    retries: retries,
    headers: {...headers},
    retryDelay: retryDelay,
  };
  if (body) {
    fetchOptions.body = body;
  }
  if (Array.isArray(retryOn)) {
    fetchOptions.retryOn = retryOn;
  } else if (typeof retryOn === 'function') {
    fetchOptions.retryOn = (attempt, error, response) =>
      retryOn(attempt, error, response, retries);
  } else if (retries > 0) {
    //If retries is greator than 0, retryOn is required
    fetchOptions.retryOn = (attempt, error, response) =>
      retryOnStatusGT500(attempt, error, response, retries);
  }

  try {
    // if retry fail after max attempt, this will throw an error
    const response = await fetchRetry(url, fetchOptions);

    let { data, errorObj } = {};
    if (!response.ok) {
      try {
        const eResponse = await response.json();
        errorObj = {
          error: eResponse.error
            ? eResponse.error
            : `An error occurred while fetching the data`,
          status: response.status
        }
      } catch (e) {
        errorObj = {
          error: `An error occurred while fetching the data`,
          status: response.status
        }
      }
    } else {
      data = await response.json();
    }
    return { data, errorObj };
  } catch (e) {
    console.log(e);
    console.log('Reached maximum retry. An error occurred while fetching the data.');
    let data = null;
    let errorObj = {
      error: `An error occurred while fetching the data`
    }
    return { data, errorObj };
  }

};
