import {
  useEffect,
  useMemo,
  useState,
  createContext,
} from 'react';
import {
  getDisplayMode,
  getPlatform,
  checkIfInKioskMode,
  checkCanActivateWakeLock,
  checkCanOpenNewWindow,
} from '../scripts/KioskModeChecker';
import { CHROME, WINDOWS } from '../scripts/Platform';
import { FULLSCREEN } from '../scripts/DisplayMode';

export const KioskContext = createContext();

const KioskProvider = ({ children }) => {
  const userAgent = navigator.userAgent;
  const platform = useMemo(() => getPlatform(), []);
  const displayMode = useMemo(() => getDisplayMode(), []);
  const [isSecure, setIsSecure] = useState(false);
  const [errorLog, setErrorLog] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [installedExtVersion, setInstalledExtVersion] = useState();
  const [canOpenNewWindow, setCanOpenNewWindow] = useState();
  const [canActivateWakeLock, setCanActivateWakeLock] = useState();

  useEffect(() => {
    if (displayMode.mode !== FULLSCREEN) {
      setErrorLog(`Application is not in fullscreen display mode.`);
      setIsLoading(false);
      return;
    }

    if (![CHROME, WINDOWS].includes(platform)) {
      setErrorLog(`${platform} - Platform not supported!`);
      setIsLoading(false);
      return;
    }

    if (platform === CHROME) {
      checkIfInKioskMode(function (error, checkLogs) {
        if (error) {
          setInstalledExtVersion(error.installedExtensionVersion);
          setErrorLog(error.errorMessage);
        } else {
          setIsSecure(true);
          setInstalledExtVersion(checkLogs.installedExtensionVersion);
          setErrorLog(checkLogs.errorMessage);
        }
        setIsLoading(false);
      });
    } else if (platform === WINDOWS && userAgent.indexOf('Edg/') > -1) {
      checkCanActivateWakeLock(function (data) {
        setCanActivateWakeLock(data.canActivateWakeLock);
        const response = checkCanOpenNewWindow();
        setCanOpenNewWindow(response.canOpenNewWindow);
        setErrorLog(`${data.errorMessage}${response.errorMessage}`);
        if (!response.canOpenNewWindow) {
          setIsSecure(true);
        }
        setIsLoading(false);
      });
    } else {
      setErrorLog('Platform not supported!');
      setIsLoading(false);
    }
  }, [displayMode, platform, userAgent]);

  return (
    <KioskContext.Provider
      value={{
        isSecure: isSecure,
        platform: platform,
        displayMode: displayMode.mode,
        isLoading: isLoading,
        errorLog: errorLog,
        installedExtVersion: installedExtVersion,
        canActivateWakeLock: canActivateWakeLock,
        canOpenNewWindow: canOpenNewWindow,
        setIsLoading: setIsLoading,
      }}
    >
      {children}
    </KioskContext.Provider>
  );
};

export default KioskProvider;
