import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const YellowButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  color: 'black',
  backgroundColor: '#f3bb51',
  '&:hover': {
    backgroundColor: '#f3bb51',
    color: 'black',
  },
  fontFamily: "'Nunito Sans',sans-serif!important",
  textTransform: 'none',
}));

export const StyledButton = (props) => {
  return (
    <YellowButton
      {...props}
      sx={{
        paddingLeft:
          props.size === 'large' ? 10 : props.size === 'medium' ? 4 : 2,
        paddingRight:
          props.size === 'large' ? 10 : props.size === 'medium' ? 4 : 2,
        paddingTop:
          props.size === 'large' ? 2 : props.size === 'medium' ? 2 : 1,
        paddingBottom:
          props.size === 'large' ? 2 : props.size === 'medium' ? 2 : 1,
        borderRadius: 8,
        fontSize: props.size === 'large' ? 20 : props.size === 'medium' ? 20 : 14,
      }}
    >
      {props.children}
    </YellowButton>
  );
};
