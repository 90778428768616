import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  LinearProgress,
} from '@mui/material';
import CustomDialogActions from './CustomDialogActions';

/**
 * A Custom Dialog component.
 * @param {Object} props The properties for the component
 * @param {boolean} props.open boolean holds the state of dialog open or close
 * @param {Object} props.children Contains the linear progress as children when used from CustomWaitDialog
 * @param {Object} props.dialogProps Contains the linear progress as children when used from CustomWaitDialog
 * @param {Array} props.dialogProps.actions An array of objects that holds button properties
 *  (a) label and (b) handle method for action of the button. The buttons are displayed only if there
 *  is no component. The assumption is if there is a component it holds DialogContent and DialogActions.
 * @param {string} props.dialogProps.title Title of the dialog
 * @param {string} props.dialogProps.message Message to display in the dialog if there is no dialog component.
 *  The assumption is if there is a component it holds DialogContent and DialogActions.
 * @param {Object} props.dialogProps.component The properties for the component that has both DialogContent and DialogActions
 * @returns
 */
const CustomDialog = ({ open, dialogProps, children }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        backdropFilter: 'blur(10px)',
      }}
    >
      {dialogProps?.showWaiting && (
        <Box sx={{ width: '100%', marginTop: 0.25 }}>
          <LinearProgress />
        </Box>
      )}
      {children}
      <DialogTitle id="alert-dialog-title">{dialogProps?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogProps?.message}
        </DialogContentText>
        {dialogProps?.component}
      </DialogContent>
      {dialogProps?.actions && (
        <CustomDialogActions actions={dialogProps?.actions} />
      )}
    </Dialog>
  );
};

export default CustomDialog;
