import { useEffect, useContext, useCallback, useState } from 'react';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import { LearnosityContainerObservable } from './LearnosityContainer.js';
import {
  LEARNOSITY_EVENTS,
  showCustomDialog,
} from '../../scripts/learnosityScripts.js';
import {
  MIN_CONSECUTIVE_ERRORS,
  PAUSED_BY_PROCTOR,
  PAUSED_BY_SYSTEM,
  PAUSED_BY_TESTER,
  SAVE_ERROR,
  SAVE_SUCCESS,
  TEST_INSESSION,
  TEST_PAUSED,
} from './TestStatus.js';
import * as PwaEvents from '../../context/PwaEvents.js';
import * as Storage from '../common/Storage.js';

const LearnosityEventHandler = () => {
  const { clearStorage, getStorageItem, setStorageItem, removeStorageItem } =
    useSessionStorage();
  const {
    pausedBy,
    testStatus,
    updateLaunchError,
    applySaveStateChanges,
    setLearnosityItemApp,
    updateTestStatus,
    updatePausedBy,
    resetPausedBy,
    learnosityItemApp,
  } = useContext(AssessmentContext);
  const { sendMsgToWebsocket } = useContext(WebSocketContext);

  const __notifiedLaunchSuccessCount =
    getStorageItem(Storage.STORAGE_NOTIFIED_LAUNCH_SUCCESS_COUNT) || 0;
  const [notifiedLaunchSuccessCount, setNotifiedLaunchSuccessCount] = useState(
    __notifiedLaunchSuccessCount
  );

  //Notified save error count
  const updateNotifiedLaunchSuccessCount = useCallback(() => {
    setNotifiedLaunchSuccessCount((prev) => {
      let newVal = prev + 1;
      setStorageItem(Storage.STORAGE_NOTIFIED_LAUNCH_SUCCESS_COUNT, newVal);
      return newVal;
    });
  }, [setStorageItem]);

  const resetNotifiedLaunchSuccessCount = useCallback(() => {
    setNotifiedLaunchSuccessCount(0);
    removeStorageItem(Storage.STORAGE_NOTIFIED_LAUNCH_SUCCESS_COUNT);
  }, [removeStorageItem]);

  const processTestPause = useCallback(
    (eventName) => {
      const maxReconnectAttemptsBeforeAuthOrReconnecting = parseInt(
        window.env.REACT_APP_WS_ATTEMPT_BEFORE_RECONNECTING_STATUS
      );

      const tempPausedBy =
        getStorageItem(Storage.STORAGE_PAUSED_BY) || pausedBy;
      const __attemptNumber = getStorageItem(Storage.STORAGE_ATTEMPT_NO) || 0;
      const __saveErrorCount =
        getStorageItem(Storage.STORAGE_SAVE_ERROR_COUNT) || 0;

      updateTestStatus(TEST_PAUSED);

      let newPausedBy;
      if (
        __saveErrorCount >= MIN_CONSECUTIVE_ERRORS ||
        __attemptNumber >= maxReconnectAttemptsBeforeAuthOrReconnecting
      ) {
        newPausedBy = PAUSED_BY_SYSTEM;
      }

      if (
        !newPausedBy &&
        ((!tempPausedBy &&
          eventName === LEARNOSITY_EVENTS.TEST_PROCTOR_PAUSE) ||
          (tempPausedBy === PAUSED_BY_PROCTOR && LEARNOSITY_EVENTS.TEST_PAUSE))
      ) {
        newPausedBy = PAUSED_BY_PROCTOR;
      }

      if (
        !newPausedBy &&
        !tempPausedBy &&
        eventName === LEARNOSITY_EVENTS.TEST_PAUSE
      ) {
        newPausedBy = PAUSED_BY_TESTER;
      }
      updatePausedBy(newPausedBy);
    },
    [pausedBy, updatePausedBy, updateTestStatus, getStorageItem]
  );

  const eventHandler = useCallback(
    ({ appInstance, eventName, error, ready }) => {
      switch (eventName) {
        case LEARNOSITY_EVENTS.TEST_FINISHED_SAVE:
        case LEARNOSITY_EVENTS.TEST_FINISHED_SUBMIT:
        case LEARNOSITY_EVENTS.TEST_PROCTOR_EXIT_AND_SUBMIT:
        case LEARNOSITY_EVENTS.TEST_PROCTOR_SAVE:
        case LEARNOSITY_EVENTS.TEST_PROCTOR_TERMINATED:
          sendMsgToWebsocket(eventName);
          clearStorage();
          break;
        case LEARNOSITY_EVENTS.TEST_READY:
          const __notifiedLaunchSuccessCount =
            getStorageItem(Storage.STORAGE_NOTIFIED_LAUNCH_SUCCESS_COUNT) ||
            notifiedLaunchSuccessCount;
          setLearnosityItemApp(appInstance);
          if (__notifiedLaunchSuccessCount === 0) {
            updateNotifiedLaunchSuccessCount();
            sendMsgToWebsocket(PwaEvents.LEARNOSITY_LAUNCH_SUCCESS);
          }
          break;
        case LEARNOSITY_EVENTS.TEST_SAVE_ERROR:
          applySaveStateChanges(SAVE_ERROR, true);
          break;
        case LEARNOSITY_EVENTS.TEST_SAVE_SUCCESS:
          applySaveStateChanges(SAVE_SUCCESS);
          break;
        case LEARNOSITY_EVENTS.TEST_PAUSE:
        case LEARNOSITY_EVENTS.TEST_PROCTOR_PAUSE:
          processTestPause(eventName);
          break;
        case LEARNOSITY_EVENTS.TEST_RESUME:
        case LEARNOSITY_EVENTS.TEST_PROCTOR_RESUMED:
          if ([PAUSED_BY_PROCTOR, PAUSED_BY_SYSTEM].includes(pausedBy)) {
            appInstance?.dialogs().custom.hide();
          } else if (pausedBy === PAUSED_BY_TESTER) {
            appInstance?.dialogs().pause.hide();
          }
          resetPausedBy();
          updateTestStatus(TEST_INSESSION);
          break;
        case LEARNOSITY_EVENTS.ITEM_LOAD:
          if (testStatus === TEST_PAUSED && pausedBy === PAUSED_BY_PROCTOR) {
            showCustomDialog(appInstance, {
              header: 'Remote control activated',
              body: 'Your activity has been paused by your administrator.',
              buttons: [],
            });
          } else if (
            testStatus === TEST_PAUSED &&
            pausedBy === PAUSED_BY_TESTER
          ) {
            appInstance?.dialogs().pause.show();
          }
          break;
        case PwaEvents.LEARNOSITY_LAUNCH_FAIL:
          if (!ready) {
            updateLaunchError(true);
            sendMsgToWebsocket('learnosity:launch:fail', true, error);
            resetNotifiedLaunchSuccessCount();
          }
          break;
        default:
          break;
      }
    },
    [
      applySaveStateChanges,
      updateLaunchError,
      sendMsgToWebsocket,
      setLearnosityItemApp,
      updateTestStatus,
      testStatus,
      pausedBy,
      resetPausedBy,
      notifiedLaunchSuccessCount,
      resetNotifiedLaunchSuccessCount,
      updateNotifiedLaunchSuccessCount,
      processTestPause,
      clearStorage,
      getStorageItem,
    ]
  );

  LearnosityContainerObservable.subscribe(eventHandler);

  useEffect(() => {
    return () => {
      LearnosityContainerObservable.unsubscribe(eventHandler);
    };
  }, [eventHandler]);

  useEffect(() => {
    while (testStatus === TEST_PAUSED) {
      let intervalId = setInterval(() => {
        learnosityItemApp.save();
      }, 30000);
      return () => {
        clearInterval(intervalId);
        intervalId = null;
      };
    }
  }, [testStatus, learnosityItemApp]);

  return <></>;
};
export default LearnosityEventHandler;
