import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { StyledButton } from '../../components/ui/input/StyledButton';
import { useNavigate } from 'react-router-dom';
import SplashImage from '../../assets/Waypoint_Delivery_Splash.png';
const styles = {
  root: {
    minHeight: '100vh',
    width: '100vw',
    margin: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundImage:  `url(${SplashImage})`,
  },
};

const Landing = () => {
  const navigate = useNavigate();

  const handleClick = async (event) => {
    navigate('/login');
  };

  return (
    <Box>
      <Grid
        container
        spacing={3}
        alignContent="center"
        alignItems="center"
        style={styles.root}
        direction="column"
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            component="h2"
            style={{
              textAlign: 'center',
              color: 'white',
              backgroundColor: 'none',
              fontFamily: "'Nunito Sans',sans-serif!important",
              marginTop:'64px'
            }}
          >
            It’s Time to Rethink <br />
            Educational Assessments
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <StyledButton onClick={handleClick} size="large">
              START
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
