import { DialogActions } from '@mui/material';
import { StyledButton } from '../input/StyledButton';

/**
 * 
 * @param {Array} props.actions An array of actions that will hold label of the button and 
 *  onClick callback handle
 * @returns 
 */
const CustomDialogActions = ({ actions }) => {
  return (
    <DialogActions>
      {actions.map((action) => {
        return (
          <StyledButton
            key={`${action.label}_${Math.random()}`}
            onClick={action.handle}
          >
            {action.label}
          </StyledButton>
        );
      })}
    </DialogActions>
  );
};

export default CustomDialogActions;
