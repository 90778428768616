import React, { useContext, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import SkeletonAssement from '../../components/assessment/SkeletonAssessment';
import WebsocketOverlays from '../../components/assessment/WebsocketOverlays.js';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import AwaitingAuthorizationDialog from '../../components/assessment/AwaitingAuthorizationDialog.js';
import CustomDialog from '../../components/ui/feedback/CustomDialog.js';
import * as TestStatus from '../../components/assessment/TestStatus.js';
import * as WebSocketStatus from '../../context/WebSocketStatus.js';
import LearnosityContainer from '../../components/assessment/LearnosityContainer.js';
import { useNavigate } from 'react-router-dom';
import ContinueDialog from '../../components/assessment/ContinueDialog.js';
import NetworkOrSaveErrorDialog from '../../components/assessment/NetworkOrSaveErrorDialog.js';
import AssessmentOverlays from '../../components/assessment/AssessmentOverlays.js';
import LearnosityEventHandler from '../../components/assessment/LearnosityEventHandler.js';

const Assessment = (props) => {
  const navigate = useNavigate();
  const {
    testStatus,
    updateTestStatus,
    updateLearnosityRequestObject,
    openErrorDialog,
    openContinueDialog,
    setOpenErrorDialog,
    setOpenContinueDialog,
  } = useContext(AssessmentContext);
  const { clearStorage } = useSessionStorage();

  const { socketStatus, lastJsonMessage } = useContext(WebSocketContext);

  const PROCTOR_DENIED_ACTIONS = [
    {
      label: 'Ok',
      handle: () => {
        navigate('/login');
      },
    },
  ];

  useEffect(() => {
    switch (socketStatus) {
      case WebSocketStatus.OPEN:
        if (testStatus === TestStatus.TEST_AUTHENTICATED) {
          updateTestStatus(TestStatus.TEST_AWAITING_AUTHORIZATION);
        }
        break;
      default:
        break;
    }
  }, [socketStatus, testStatus, updateTestStatus]);

  useEffect(() => {
    if (
      lastJsonMessage?.messageType === 'proctorAction' &&
      lastJsonMessage?.messageData?.action === 'LAUNCH'
    ) {
      updateTestStatus(TestStatus.TEST_INSESSION);
      updateLearnosityRequestObject(
        lastJsonMessage.messageData.request_obj?.learnositySignedPayload
      );
    }
    if (
      lastJsonMessage?.messageType === 'proctorAction' &&
      lastJsonMessage?.messageData?.action === 'ERROR'
    ) {
      updateTestStatus(TestStatus.TEST_DENIED);
      clearStorage();
    }
  }, [
    lastJsonMessage,
    updateLearnosityRequestObject,
    clearStorage,
    updateTestStatus,
  ]);
  return (
    <>
      <WebsocketOverlays />
      <AssessmentOverlays />
      <NetworkOrSaveErrorDialog
        open={openErrorDialog}
        setOpen={setOpenErrorDialog}
      />
      <ContinueDialog
        open={openContinueDialog}
        setOpen={setOpenContinueDialog}
      />
      {testStatus === TestStatus.TEST_AWAITING_AUTHORIZATION && (
        <AwaitingAuthorizationDialog />
      )}
      {testStatus === TestStatus.TEST_DENIED && (
        <CustomDialog
          open={true}
          dialogProps={{
            title: 'Awaiting Authorization',
            message: 'Proctor denied access to testing service.',
            actions: PROCTOR_DENIED_ACTIONS,
          }}
        />
      )}
      <Box
        sx={{
          width: '90vw',
          height: '90vh',
          paddingTop: '10px',
        }}
      >
        <Paper
          elevation={2}
          square={false}
          sx={{
            width: '90vw',
          }}
        >
          <Box
            sx={{
              width: '90vw',
              height: '85vh',
              padding: '10px',
            }}
          >
            {![TestStatus.TEST_INSESSION, TestStatus.TEST_PAUSED].includes(testStatus) && <SkeletonAssement />}
            {[TestStatus.TEST_INSESSION, TestStatus.TEST_PAUSED].includes(testStatus) && (
              <>
                <LearnosityEventHandler />
                <LearnosityContainer />
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default Assessment;
