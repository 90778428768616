import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import ErrorDialog from './ErrorDialog.js';

const LaunchErrorDialog = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { clearStorage } = useSessionStorage();

  const onComplete = useCallback(() => {
    clearStorage();
    setOpen(false);
    navigate('/login');
  }, [navigate, clearStorage, setOpen]);

  const LAUNCH_ERROR_ACTIONS = [
    {
      label: 'Ok',
      handle: () => onComplete(),
    },
  ];

  const getComponentCallback = useCallback((countdownSeconds) => {
    return (
      <Typography>
        An Error occurred trying to launch the assessment. The proctor has been
        notified. Closing assessment in <b>{countdownSeconds}</b> seconds.
      </Typography>
    );
  }, []);

  return (
    <ErrorDialog
      open={open}
      title="Assessment launch error"
      getComponentCallback={getComponentCallback}
      onComplete={onComplete}
      actions={LAUNCH_ERROR_ACTIONS}
      duration={30}
    />
  );
};

export default LaunchErrorDialog;
