import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomDialog from '../ui/feedback/CustomDialog';
import { sleep } from '../../scripts/common/helper.js';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import * as PwaEvents from '../../context/PwaEvents.js';

/**
 *
 * @param {Object} props The properties for the component
 * @param {boolean} props.message Message to display in the dialog.
 * @param {boolean} props.title Title of the dialog
 * @returns
 */
const AwaitingAuthorizationDialog = () => {
  const navigate = useNavigate();
  const { clearStorage } = useSessionStorage();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { sendMsgToWebsocket } = useContext(WebSocketContext);

  //Dialog actions for Awaiting authorization
  const AWAITING_AUTH_ACTIONS = [
    {
      label: 'Cancel',
      handle: () => {
        setOpenConfirmation(true);
      },
    },
  ];

  const [awaitAuthDialogProps, setAuthDialogProps] = useState({
    title: 'Awaiting Authorization',
    message: 'Awaiting proctor authorization. Please wait...',
    actions: AWAITING_AUTH_ACTIONS,
  });

  //Dialog actions for cancel awaiting confirmation
  const AWAITING_AUTH_CANCEL_CONFIRMATION_ACTIONS = [
    {
      label: 'Yes',
      handle: async () => {
        setOpenConfirmation(false);
        setAuthDialogProps({
          title: 'Awaiting Authorization',
          message: 'Cancelling...',
        });

        //Send abort message
        sendMsgToWebsocket(PwaEvents.PWA_AUTH_CANCEL);

        //Sleep for 3 seconds
        await sleep(3000);

        //Clear storage
        clearStorage();
        //redirect back to login screen
        navigate('/login');
      },
    },
    {
      label: 'No, continue waiting',
      handle: () => {
        setOpenConfirmation(false);
      },
    },
  ];

  return (
    <>
      {openConfirmation && (
        <CustomDialog
          open={true}
          dialogProps={{
            title: 'Cancel Authorization',
            message:
              'Warning:  This will return you to the login screen.  Do you want to log out?',
            actions: AWAITING_AUTH_CANCEL_CONFIRMATION_ACTIONS,
          }}
        />
      )}
      <CustomDialog open={true} dialogProps={awaitAuthDialogProps} />
    </>
  );
};

export default AwaitingAuthorizationDialog;
