export class Observable {
  constructor() {
    this.observers = [];
  }

  subscribe(func) {
    //If not already added
    const found = this.observers.find((obj) => obj.name === func.name);
    if (!found) {
      this.observers.push(func);
    }
  }

  unsubscribe(func) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(...data) {
    this.observers.forEach((observer) => observer(...data));
  }
}
