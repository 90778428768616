import Landing from '../../pages/landing/Landing';
import Login from '../../pages/login/Login';
import Layout from '../../components/common/Layout';
import Assessment from '../../pages/assessment/Assessment';
import {
  createBrowserRouter,
} from "react-router-dom";

export const routes = [
  {
    path: '/',
    element: <Layout landing={true} />,
    children: [
      { path: "/", element: <Landing /> },
    ]
  },
  {
    path: '/',
    element: <Layout login={true}/>,
    children: [
      { path: "/login", element: <Login /> },
    ]
  },
  {
    path: '/',
    element: <Layout disableMenu={true} />,
    children: [
      { path: "/assessment", element: <Assessment /> }
    ]
  }
];

export const router = createBrowserRouter(routes);