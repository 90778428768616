import { useContext, useEffect, useState } from 'react';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import {
  getCustomDialogButtons,
  showCustomDialog,
} from '../../scripts/learnosityScripts.js';
import CustomDialog from '../ui/feedback/CustomDialog';

const ErrorDialog = ({
  open,
  runBackground,
  onComplete,
  title,
  getComponentCallback,
  actions,
  duration,
}) => {
  //name of session storage variable
  const STORAGE_AUTO_EXIT_SECONDS = 'autoExitSeconds';
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useSessionStorage();
  const { learnosityItemApp } = useContext(AssessmentContext);

  //Get auto exit seconds from session storage and update useState

  const [timeDurationSeconds, setTimeDurationSeconds] = useState();
  const [countdownSeconds, setCountdownSeconds] = useState();

  const DIALOG_DETAILS = {
    header: title,
    body: getComponentCallback(countdownSeconds),
    buttons: getCustomDialogButtons(actions, learnosityItemApp),
  };

  useEffect(() => {
    if (open) {
      const __savedAutoExitSeconds =
        getStorageItem(STORAGE_AUTO_EXIT_SECONDS) || 0;
      setTimeDurationSeconds(
        __savedAutoExitSeconds > 0 ? __savedAutoExitSeconds : duration
      );
      //Create an interval for the requested duration
      let intervalId = setInterval(() => {
        setTimeDurationSeconds((prev) => {
          const newTimeDuration = prev - 1;

          if (newTimeDuration <= 0) {
            clearInterval(intervalId);
            intervalId = null;
            onComplete();
          }
          //updaate session storage
          setStorageItem(STORAGE_AUTO_EXIT_SECONDS, newTimeDuration);
          return newTimeDuration;
        });
      }, 1000);

      return () => {
        clearInterval(intervalId);
        intervalId = null;
        //remove from session storage
        removeStorageItem(STORAGE_AUTO_EXIT_SECONDS);
        setTimeDurationSeconds();
      };
    }
  }, [
    duration,
    onComplete,
    open,
    getStorageItem,
    setStorageItem,
    removeStorageItem,
  ]);

  useEffect(() => {
    const minutes = Math.floor(timeDurationSeconds / 60);
    const seconds = timeDurationSeconds % 60;
    setCountdownSeconds(
      `${minutes > 0 ? `${minutes} minutes` : ``} ${
        seconds < 10 ? '0' + seconds : seconds
      } seconds`
    );
  }, [timeDurationSeconds]);

  return (
    <>
      {open && !runBackground && !learnosityItemApp && (
        <CustomDialog
          open={open}
          dialogProps={{
            title: title,
            component: getComponentCallback(countdownSeconds),
            showWaiting: true,
            actions: actions,
          }}
        />
      )}
      {open &&
        !runBackground &&
        learnosityItemApp &&
        showCustomDialog(learnosityItemApp, DIALOG_DETAILS)}
    </>
  );
};

export default ErrorDialog;
