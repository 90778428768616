import React, { useState, useContext } from 'react';
import { Grid, TextField, Typography, Box, Alert } from '@mui/material';
import { StyledButton } from '../../components/ui/input/StyledButton';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import { isNotEmpty } from '../../scripts/common/helper';
import { customFetch } from '../../scripts/common/customFetch';
import useSessionStorage from '../../hooks/useSessionStorage';
import { KioskContext } from '../../context/KioskProviderContext';
import * as Storage from "../../components/common/Storage";
import { TEST_AUTHENTICATED } from '../../components/assessment/TestStatus';

const Login = (props) => {
  const navigate = useNavigate();
  const { isSecure } = useContext(KioskContext);
  const [setIsLoading] = useOutletContext();
  const { clearStorage, setStorageItem } = useSessionStorage();
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [loginError, setLoginError] = useState('');
  const {
    value: idValue,
    isValid: idIsValid,
    hasError: idHasError,
    valueChangeHandler: idChangeHandler,
    inputBlurHandler: idBlurHandler,
    reset: idReset,
  } = useInput(isNotEmpty);

  const {
    value: accessCodeValue,
    isValid: accessCodeIsValid,
    hasError: accessCodeHasError,
    valueChangeHandler: accessCodeChangeHandler,
    inputBlurHandler: accessCodeBlurHandler,
    reset: accessCodeReset,
  } = useInput(isNotEmpty);

  const expBackOffLogin = () => {
    setLoginDisabled(true);
    let backOfftime = Math.min(
      5 * 60 * 1000,
      (Math.pow(2, attemptCount) + Math.random()) * 1000
    );
    setTimeout(() => {
      setLoginDisabled(false);
    }, backOfftime);
  };

  const logInUser = async () => {
    clearStorage();

    setIsLoading(true);
    const { data, errorObj } = await customFetch({
      url: `${window.env.REACT_APP_LOGIN_URL}?id=${idValue}&accessCode=${accessCodeValue}&secureStatus=${isSecure}`,
    });
    idReset();
    accessCodeReset();
    let errorMsg = '';
    if (errorObj) {
      setAttemptCount((prevState) => prevState + 1);

      if (errorObj.status === 403) {
        if (errorObj.error === 'CREDENTIALS_NOT_FOUND') {
          errorMsg += 'Invalid login.';
        } else if (errorObj.error === 'SECURED_CLIENT_REQUIRED') {
          errorMsg += 'You must launch the Waypoint Client in Kiosk Mode.';
        } else if (errorObj.error === 'NON_SECURED_CLIENT_REQUIRED') {
          errorMsg +=
            'Your test cannot be launched in kiosk mode.  Please launch Waypoint Client outside of kiosk.';
        } else if (errorObj.error.startsWith('UNAUTHORIZED:')) {
          errorMsg += errorObj.error.replace('UNAUTHORIZED:', '');
        } else {
          errorMsg += 'Something went wrong while trying to login.';
        }
      } else {
        errorMsg += 'Something went wrong while trying to login.';
      }
      if (attemptCount >= 2) {
        expBackOffLogin();
        errorMsg = 'Too many attempts. Please try again later.';
      }
      setIsLoading(false);
      setLoginError(errorMsg);
    } else {
      setIsLoading(false);
      setStorageItem(Storage.STORAGE_AUTH_TOKEN, data.authToken);
      setStorageItem(Storage.STORAGE_TEST_STATUS, TEST_AUTHENTICATED);
      navigate('/assessment', { state: data });
    }
  };

  return (
    <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Grid
        container
        spacing={5}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid container item direction="column">
          <Grid item>
            <Typography variant="h3" alignContent="left">
              Welcome to Waypoint!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Please enter your credentials to start the assessment.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={5}>
          <Grid item>
            <TextField
              required
              error={idHasError}
              label="Id"
              helperText="Enter your Id."
              variant="outlined"
              sx={{ width: 400 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ 'data-testid': 'id-input' }}
              value={idValue}
              onChange={idChangeHandler}
              onBlur={idBlurHandler}
              disabled={loginDisabled}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              error={accessCodeHasError}
              label="Access Code"
              helperText="Enter the Access Code."
              variant="outlined"
              sx={{ width: 400 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ 'data-testid': 'access-code-input' }}
              value={accessCodeValue}
              onChange={accessCodeChangeHandler}
              onBlur={accessCodeBlurHandler}
              disabled={loginDisabled}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {loginError !== '' && <Alert severity="error">{loginError}</Alert>}
          </Grid>
          <Grid item>
            <StyledButton
              disabled={!idIsValid || !accessCodeIsValid || loginDisabled}
              onClick={logInUser}
              size="medium"
            >
              Login
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Login;
