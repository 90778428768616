import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
  Typography,
  Drawer,
  Divider,
  Box,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import About from './About';
import InfoIcon from '@mui/icons-material/Info';
import CustomDialog from '../ui/feedback/CustomDialog';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WaypointLogo from '../../assets/Waypoint_Logo_Horizontal_D_RGB.png';

const Header = (props) => {
  const disableMenu = props.disableMenu ? props.disableMenu : false;
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [openAboutDialog, setOpenAboutDialog] = useState(false);

  const toggleMenu = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: '#003d4d!important',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ pr: '24px' }}>
          {!disableMenu && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={toggleMenu(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            component="img"
            sx={{
              height: '40px',
              padding: '.5px',
              alignContent: 'start',
            }}
            alt="Your logo."
            src={WaypointLogo}
          />
          <Box sx={{ flexGrow: 1 }} />
          {disableMenu && state && <Typography>{state.name}</Typography>}
        </Toolbar>
        <Drawer open={open} onClose={toggleMenu(false)}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ width: 200 }} role="presentation">
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setOpenAboutDialog(true)}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography variant="caption" textAlign="right">
                          About Waypoint
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
          </Box>
        </Drawer>
      </AppBar>
      <CustomDialog
        open={openAboutDialog}
        dialogProps={{
          title: 'Waypoint Client',
          component: <About />,
          actions: [
            {
              label: 'Close',
              handle: () => {
                setOpenAboutDialog(false);
              },
            },
          ],
        }}
      />
    </React.Fragment>
  );
};
export default Header;
