//Test save status
export const STORAGE_SAVE_STATE = 'saveState';
export const STORAGE_SAVE_ERROR_COUNT = 'saveErrorCount';
export const STORAGE_NOTIFIED_SAVE_SUCCESS_COUNT = 'notifiedSaveSuccessCount';
export const STORAGE_NOTIFIED_SAVE_ERROR_COUNT = 'notifiedSaveErrorCount';
export const STORAGE_NOTIFIED_LAUNCH_SUCCESS_COUNT = 'notifiedLaunchSuccessCount';

export const STORAGE_SIGNED_OBJECT = 'signedObject';
export const STORAGE_TEST_STATUS = 'testStatus';
export const STORAGE_PAUSED_BY = 'pausedBy';
export const STORAGE_ATTEMPT_NO_BEFORE_RELOAD = 'attemptNumBeforeReload';
export const STORAGE_ATTEMPT_NO = 'attemptNumber';

export const STORAGE_AUTH_TOKEN = 'authToken';
