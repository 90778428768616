import { useContext } from 'react';
import {
  getCustomDialogButtons,
  hideCustomDialog,
  showCustomDialog,
} from '../../scripts/learnosityScripts.js';
import { AssessmentContext } from '../../context/AssessmentContext.js';

const ContinueDialog = ({ open, setOpen }) => {
  const { learnosityItemApp } = useContext(AssessmentContext);
  const DIALOG_ACTIONS = [
    {
      id: 'wp_networkorsave_continue',
      label: 'Continue',
      handle: () => {
        setOpen(false);
        hideCustomDialog(learnosityItemApp);
      },
      primary: true,
    },
  ];
  const DIALOG_DETAILS = {
    header: 'Connection restored to testing service',
    body: "Click 'Continue' button to continue testing.",
    buttons: getCustomDialogButtons(DIALOG_ACTIONS, learnosityItemApp),
  };
  return (
    <>{open && showCustomDialog(learnosityItemApp, DIALOG_DETAILS)}</>
  );
};

export default ContinueDialog;
