import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './components/common/Route';
import KioskProvider from './context/KioskProviderContext';

const App = () => {
  return (
    <KioskProvider>
      <RouterProvider router={router} />
    </KioskProvider>
  );
};
export default App;
