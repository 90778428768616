export const TEST_AUTHENTICATED = 'authenticated';
export const TEST_AWAITING_AUTHORIZATION = 'awaitingAuthorization';
export const TEST_INSESSION = 'inSession';
export const TEST_DENIED = 'denied';
export const TEST_PAUSED = 'paused';

export const PAUSED_BY_TESTER = 'tester';
export const PAUSED_BY_PROCTOR = 'proctor';
export const PAUSED_BY_SYSTEM = 'system';

export const SAVE_ERROR = 'error';
export const SAVE_SUCCESS = 'success';

export const MIN_CONSECUTIVE_ERRORS = 3;
