import React, { useState, useEffect, useContext, useMemo } from 'react';
import CustomDialog from '../../components/ui/feedback/CustomDialog';
import { useNavigate } from 'react-router-dom';
import { AssessmentContext } from '../../context/AssessmentContext.js';
import { WebSocketContext } from '../../context/WebSocketContext.js';
import * as WebSocketStatus from '../../context/WebSocketStatus.js';
import useSessionStorage from '../../hooks/useSessionStorage.js';
import * as TestStatus from './TestStatus.js';
import * as Storage from '../common/Storage.js';

const WebsocketOverlays = () => {
  const navigate = useNavigate();
  const {
    openErrorDialog,
    setOpenErrorDialog,
    setOpenContinueDialog,
    saveErrorCount,
    learnosityItemApp,
    updatePausedBy,
  } = useContext(AssessmentContext);
  const { socketStatus } = useContext(WebSocketContext);
  const { clearStorage, getStorageItem } = useSessionStorage();

  const storedTestStatus = getStorageItem(Storage.STORAGE_TEST_STATUS);
  const [awaitingConnection, setAwaitingConnection] = useState(
    TestStatus.TEST_PAUSED === storedTestStatus ? false : true
  );

  const __attemptNumber = getStorageItem(Storage.STORAGE_ATTEMPT_NO) || 0;

  const [connectDialogProps, setConnectDialogProps] = useState(
    ![TestStatus.TEST_INSESSION, TestStatus.TEST_PAUSED].includes(
      storedTestStatus
    )
      ? {
          title: 'Awaiting Connection',
          message: 'Connecting to testing services. Please wait...',
          showWaiting: true,
        }
      : TestStatus.TEST_PAUSED !== storedTestStatus
      ? {
          title: 'Reconnecting',
          message: 'Connecting to testing services. Please wait...',
          showWaiting: true,
        }
      : undefined // we do not want to show awaiting or reconnecting on test pause
  );

  //actions for failed connection
  const AWAITING_CONNECTION_FAILED_ACTIONS = useMemo(() => {
    return [
      {
        label: 'Ok',
        handle: () => {
          clearStorage();
          //redirect back to login screen
          navigate('/login');
        },
      },
    ];
  }, [clearStorage, navigate]);

  useEffect(() => {
    const maxReconnectAttemptsBeforeAuthOrReconnecting = parseInt(
      window.env.REACT_APP_WS_ATTEMPT_BEFORE_RECONNECTING_STATUS
    );
    switch (socketStatus) {
      case WebSocketStatus.INITIAL:
        if (
          [TestStatus.TEST_INSESSION, TestStatus.TEST_PAUSED].includes(
            storedTestStatus
          ) &&
          (saveErrorCount >= TestStatus.MIN_CONSECUTIVE_ERRORS ||
            __attemptNumber >= maxReconnectAttemptsBeforeAuthOrReconnecting)
        ) {
          setAwaitingConnection(false);
          updatePausedBy(TestStatus.PAUSED_BY_SYSTEM);
          setOpenErrorDialog(true);
        } else if (
          [TestStatus.TEST_INSESSION].includes(storedTestStatus) &&
          (saveErrorCount >= 0 || __attemptNumber >= 0)
        ) {
          setAwaitingConnection(false);
        } else {
          setConnectDialogProps({
            title: 'Awaiting Connection',
            message: 'Connecting to testing services. Please wait...',
            showWaiting: true,
          });
        }
        break;
      case WebSocketStatus.OPEN:
        setAwaitingConnection(false);
        if (openErrorDialog && saveErrorCount === 0) {
          setOpenErrorDialog(false);
          setOpenContinueDialog(true);
        }
        break;
      case WebSocketStatus.FAILED_TO_CONNECT:
        if ([TestStatus.TEST_INSESSION].includes(storedTestStatus)) {
          setConnectDialogProps({
            title: 'Reconnecting',
            message: 'Could not reconnect to testing services...',
            showWaiting: false,
            actions: AWAITING_CONNECTION_FAILED_ACTIONS,
          });
        } else {
          setConnectDialogProps({
            title: 'Awaiting Connection',
            message: 'Failed to establish connection...',
            showWaiting: false,
            actions: AWAITING_CONNECTION_FAILED_ACTIONS,
          });
        }
        break;
      case WebSocketStatus.RECONNECTING:
        setAwaitingConnection(false);
        updatePausedBy(TestStatus.PAUSED_BY_SYSTEM);
        setOpenErrorDialog(true);
        break;
      case WebSocketStatus.FAILED_TO_RECONNECT:
        setAwaitingConnection(true);
        setConnectDialogProps({
          title: 'Failed to establish connection',
          message: 'Could not reconnect to testing services...',
          showWaiting: false,
          actions: AWAITING_CONNECTION_FAILED_ACTIONS,
        });
        break;

      default:
        break;
    }
  }, [
    socketStatus,
    storedTestStatus,
    AWAITING_CONNECTION_FAILED_ACTIONS,
    setOpenErrorDialog,
    openErrorDialog,
    setOpenContinueDialog,
    __attemptNumber,
    saveErrorCount,
    learnosityItemApp,
    updatePausedBy,
  ]);

  return (
    <CustomDialog open={awaitingConnection} dialogProps={connectDialogProps} />
  );
};

export default WebsocketOverlays;
