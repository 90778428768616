import React, { useContext } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import { KioskContext } from '../../context/KioskProviderContext';
import { CHROME, WINDOWS } from '../../scripts/Platform';

const About = () => {
  const {
    platform,
    displayMode,
    isSecure,
    installedExtVersion,
    canActivateWakeLock,
    canOpenNewWindow,
    errorLog,
  } = useContext(KioskContext);

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item xs={8}>
          Application Version
        </Grid>
        <Grid item xs={4}>
          <i>v{process.env.REACT_APP_VERSION}</i>
        </Grid>
        {platform === CHROME && (
          <>
            <Grid item xs={8}>
              Chrome Extension Version expected
            </Grid>
            <Grid item xs={4}>
              <i>v{window.env.REACT_APP_EXT_VERSION}</i>
            </Grid>
            <Grid item xs={8}>
              Installed Chrome Extension Version
            </Grid>
            <Grid item xs={4}>
              {installedExtVersion && <i>v{installedExtVersion}</i>}
              {!installedExtVersion && <i>n/a</i>}
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          Platform
        </Grid>
        <Grid item xs={4}>
          {platform}
        </Grid>
        <Grid item xs={8}>
          Display
        </Grid>
        <Grid item xs={4}>
          {displayMode}
        </Grid>
        <Grid item xs={8}>
          Kiosk
        </Grid>
        <Grid item xs={4}>
          {isSecure ? 'Yes' : 'No'}
        </Grid>
        {platform === WINDOWS && (
          <>
            <Grid item xs={8}>
              Wake Lock Support
            </Grid>
            <Grid item xs={4}>
              {canActivateWakeLock ? 'Yes' : 'No'}
            </Grid>
            <Grid item xs={8}>
              New Window
            </Grid>
            <Grid item xs={4}>
              {canOpenNewWindow ? 'Yes' : 'No'}
            </Grid>
          </>
        )}
      </Grid>
      {window.env.REACT_APP_DEBUG && !isSecure && (
        <TextField multiline fullWidth value={`Kiosk check logs: \n${errorLog}`} />
      )}
    </Stack>
  );
};

export default About;
