export const LEARNOSITY_EVENTS = {
  TEST_FINISHED_SAVE: 'test:finished:save',
  TEST_FINISHED_SUBMIT: 'test:finished:submit',
  TEST_PROCTOR_EXIT_AND_SUBMIT: 'test:proctor:exitAndSubmit',
  TEST_PROCTOR_SAVE: 'test:proctor:save',
  TEST_PROCTOR_TERMINATED: 'test:proctor:terminated',
  TEST_READY: 'test:ready',
  TEST_SAVE_ERROR: 'test:save:error',
  TEST_SAVE_SUCCESS: 'test:save:success',
  TEST_PAUSE: 'test:pause',
  TEST_PROCTOR_PAUSE: 'test:proctor:paused',
  TEST_RESUME: 'test:resume',
  TEST_PROCTOR_RESUMED: 'test:proctor:resumed',
  ITEM_LOAD: 'item:load'
};

const attachEventHandlers = (itemsApp, eventEmitter) => {
  //add events here.
  Object.values(LEARNOSITY_EVENTS).forEach((event) => {
    itemsApp.on(event, function () {
      eventEmitter({ appInstance: itemsApp, eventName: event });
    });
  });
};

const initItemsApp = (request, learnosityComponentCallback, eventEmitter) => {
  var itemsApp = window.LearnosityItems.init(
    request,
    learnosityComponentCallback
  );
  //attach events here so that test:ready event gets triggered
  attachEventHandlers(itemsApp, eventEmitter);
  return itemsApp;
};

const resetItemsApp = (itemsApp) => {
  if (itemsApp) {
    try {
      console.log('Resetting itemsApp for reinitialization');
      itemsApp.off();
      itemsApp.reset();
    } catch (error) {
      console.log(error);
    }
  }
};

/**
 * Returns button array.
 * [ {
 *    button_id: unique_html_button_id,
 *    label: buttonLabel,
 *    is_primary: isItAPrimaryButton
 * }]
 * Also registers the button click action with itemApp object
 *
 * @param {*} actions Expected format is
 * [{
 *     id: unique_html_button_id,
 *     primary: isItAPrimaryButton_true/false
 *     handle: 'A method to callback on button click'
 * }]
 * @param {*} appInstance learnosity's itemApp object
 * @returns button array
 */
const getCustomDialogButtons = (actions, appInstance) => {
  let buttons = [];
  for (let action of actions) {
    const button = {
      button_id: action.id,
      label: action.label,
      is_primary: action.primary,
    };
    appInstance?.assessApp()?.on(`button:${action.id}:clicked`, function () {
      action.handle();
    });
    buttons.push(button);
  }
  return buttons;
};

const showCustomDialog = (appInstance, dialogDetails = {}) => {
  return appInstance
    ?.assessApp()
    ?.dialogs()
    ?.custom?.show({ ...dialogDetails });
};

const hideCustomDialog = (appInstance) => {
  return appInstance
    ?.assessApp()
    ?.dialogs()
    ?.custom?.hide();
};

export {
  initItemsApp,
  resetItemsApp,
  getCustomDialogButtons,
  showCustomDialog,
  hideCustomDialog
};
