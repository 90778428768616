import { BROWSER, FULLSCREEN, MINIMAL_UI, STANDALONE, WINDOW_CONTROLS_OVERLAY } from './DisplayMode';
import { CHROME, LINUX, MACOS, WINDOWS } from './Platform';

const EXTENSION_ID = `${window.env.REACT_APP_EXT_ID}`;
const EXTENSION_VER = `${window.env.REACT_APP_EXT_VERSION}`;

//This is for logging messages
const logMsg = (msg, append) => {
  msg.errorMessage = msg.errorMessage.concat(`${append} \n`);
};

const callExtensionToVerify = (data, callback) => {
  logMsg(data, 'Checking with network service.');
  chrome.runtime.sendMessage(
    EXTENSION_ID,
    {
      methodName: 'verifyIfInKiosk',
    },
    function (response) {
      logMsg(data, 'Response received.');
      if (
        response &&
        response.IS_IN_KIOSK !== undefined &&
        response.IS_IN_KIOSK !== null
      ) {
        if (response.IS_IN_KIOSK) {
          logMsg(data, JSON.stringify(response));
          logMsg(data, 'APP is in KIOSK');
          callback(null, data);
        } else {
          logMsg(data, JSON.stringify(response));
          logMsg(data, 'APP is NOT in KIOSK');
          callback(data);
        }
      } else {
        logMsg(
          data,
          'Something went wrong with the call to Google verify API.'
        );
        logMsg(data, JSON.stringify(response));
        callback(data);
      }
    }
  );
};

const checkIfInKioskMode = (callback) => {
  let response = { errorMessage: '' };
  // 1.initial check if extension exists and correct version
  logMsg(response, 'Checking if extension exists and correct version...');
  if (chrome && chrome.runtime) {
    logMsg(
      response,
      'chrome & chrome.runtime is defined. now checking extension...'
    );
    // 2. check extension version
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      {
        methodName: 'getVersion',
      },
      function (extensionVersion) {
        response.installedExtensionVersion = extensionVersion;
        logMsg(
          response,
          'Extension version from extension: ' +
            extensionVersion +
            ' Extension version in PWA: ' +
            EXTENSION_VER
        );
        if (extensionVersion === EXTENSION_VER) {
          // If extension found and correct version.
          // 3. call extension to verify
          callExtensionToVerify(response, callback);
        } else {
          logMsg(response, 'Invalid extension/version found.');
          callback(response);
        }
      }
    );
  } else {
    logMsg(response, `chrome - ${chrome} & chrome.runtime - ${chrome?.runtime}`);
    logMsg(response, 'No extension found.');
    callback(response);
  }
};

const checkCanOpenNewWindow = () => {
  let response = { errorMessage: '' };
  let obj = window.open(
    null,
    '_blank',
    'toolbar=no,scrollbars=no,resizable=no,left=1,width=1,height=1,popup=0'
  );
  if (obj) {
    obj.close();
    window.focus();
    response.canOpenNewWindow = true;
    logMsg(response, 'Not in Kiosk mode. Can open new window.');
  } else {
    response.canOpenNewWindow = false;
    logMsg(response, 'In Kiosk mode. Cannot open new window.');
  }
  return response;
};

const checkCanActivateWakeLock = async (callback) => {
  let response = { errorMessage: '' };
  if ('wakeLock' in navigator) {
    response.canActivateWakeLock = true;
    logMsg(
      response,
      `Screen Wake Lock API supported! Attempting to activate/request wake lock.  Wake Lock object: ${JSON.stringify(
        navigator.wakeLock
      )}. `
    );
  } else {
    response.canActivateWakeLock = false;
    logMsg(
      response,
      `Screen Wake lock API is not supported. Cannot activate wake lock. In Kiosk mode! Wake Lock object: ${navigator.wakeLock}. `
    );
  }

  let wakeLock;
  try {
    wakeLock = await navigator.wakeLock.request('screen');
    response.canActivateWakeLock = true;
    logMsg(response, 'Wake lock is activated. ');
  } catch (err) {
    // The wake lock request fails - usually system-related, such as low battery.
    response.canActivateWakeLock = false;
    logMsg(
      response,
      `Error activating Wake lock, application is in Kiosk mode. ${err.name}, ${err.message}. `
    );
    return callback(response);
  }

  try {
    wakeLock.addEventListener('release', (event) => {
      response.canActivateWakeLock = true;
      logMsg(response, 'Wakelock released, application NOT in Kiosk mode. ');
    });
    await wakeLock.release();
  } catch (err) {
    // The wake lock request fails - usually system-related, such as low battery.
    response.canActivateWakeLock = false;
    logMsg(response, 'Wakelock release failed.');
  }
  return callback(response);
};

const getDisplayMode = () => {
  const displayMode = {};

  if (window.matchMedia('(display-mode: fullscreen)').matches) {
    displayMode.mode = FULLSCREEN;
    displayMode.text = 'Application is running in fullscreen display mode.';
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    displayMode.mode = STANDALONE;
    displayMode.text = 'Application is running in standalone display mode.';
  }

  if (window.matchMedia('(display-mode: minimal-ui)').matches) {
    displayMode.mode = MINIMAL_UI;
    displayMode.text = 'Application is running in minimal-ui display mode.';
  }

  if (window.matchMedia('(display-mode: browser)').matches) {
    displayMode.mode = BROWSER;
    displayMode.text = 'Application is running in browser display mode.';
  }

  if (window.matchMedia('(display-mode: window-controls-overlay)').matches) {
    displayMode.mode = WINDOW_CONTROLS_OVERLAY;
    displayMode.text =
      'Application is running in window-controls-overlay display mode.';
  }
  return displayMode;
};

const getPlatform = () => {
  if (!('navigator' in window)) {
    return 'unknown';
  }

  // Use the modern 'web hints' provied by
  // 'userAgentData' if available, else use
  // the deprecated 'platform' as fallback.
  const platform = (
    navigator.userAgentData?.platform || navigator.platform
  )?.toLowerCase();

  if (platform.startsWith('win')) return WINDOWS;
  if (platform.startsWith('mac')) return MACOS;
  if (platform.startsWith('linux')) return LINUX;
  if (platform.startsWith('chrome')) return CHROME;
  return 'unknown';
};

export {
  checkIfInKioskMode,
  getDisplayMode,
  getPlatform,
  checkCanActivateWakeLock,
  checkCanOpenNewWindow,
};
